<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'alunos' })"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Aluno
        </h1>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
        <v-row>
          <v-col cols="12">
            <template>
              <!--UPLOAD-->
              <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving">
                <h4>Carregar imagem</h4>
                <div class="dropbox">
                  <input
                    type="file"
                    multiple
                    :name="uploadFieldName"
                    @change="
                      filesChange($event.target.files);
                      fileCount = $event.target.files.length;
                    "
                    accept="image/*"
                    class="input-file"
                  />
                  <p v-if="isInitial">
                    Arraste uma imagem aqui para começar<br />
                    ou clique para navegar
                  </p>
                  <v-col class="text-center" v-if="isSaving">
                    <img class="text-center" width="150x" :src="base64Img" />
                    <p>Imagem carregada!</p>
                  </v-col>
                </div>
              </form>
            </template>
          </v-col>
          <v-col class="pt-0 pb-0" cols="8">
            <ValidationProvider name="Nome Completo" rules="required" v-slot="{ errors }">
              <e-label>Nome Completo</e-label>
              <v-text-field :error-messages="errors" v-model="form.nomecompleto" dense solo />
            </ValidationProvider>
          </v-col>

          <v-col class="pt-0 pb-0" cols="4">
            <e-label>Número do CPF</e-label>
            <v-text-field v-mask="'###.###.###-##'" v-model="form.cpf" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>Identificação única(INEP)</e-label>
            <v-text-field v-model="form.codigo_inep" :loading="pesquisandoINEP" dense solo>
            </v-text-field>
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>Data de nascimento</e-label>
            <!-- <v-text-field :error-messages="errors" v-model="form.data_nascimento" dense solo /> -->
            <e-date-picker v-model="form.data_nascimento" placeholder="dd/mm/yyyy"></e-date-picker>
          </v-col>
          <v-col class="pt-0 pb-0" cols="12">
            <ValidationProvider name="Filiação 1" rules="required" v-slot="{ errors }">
              <e-label>Filiação 1</e-label>
              <v-text-field :error-messages="errors" v-model="form.filiacao1" dense solo />
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0" cols="12">
            <e-label>Filiação 2</e-label>
            <v-text-field v-model="form.filiacao2" dense solo />
          </v-col>

          <v-col class="pt-0 pb-0" cols="6">
            <e-label>Sexo </e-label>
            <e-autocomplete
              :items="$constants.sexoList"
              :return-object="false"
              :item-value="(value) => value.id"
              :item-text="(value) => value.descricao"
              v-model="form.sexo_id"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>Cor/Raça</e-label>
            <e-autocomplete
              :items="$constants.corOuRacaList"
              :return-object="false"
              v-model="form.cor_ou_raca"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>Nacionalidade</e-label>
            <e-autocomplete
              :items="$constants.nacionalidadeList"
              :return-object="false"
              :item-text="(value) => value"
              :item-value="(value) => value"
              v-model="form.naturalidade"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>País de nacionalidade</e-label>
            <v-text-field v-model="form.nacionalidade" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>UF de nascimento</e-label>
            <e-autocomplete
              :items="$constants.estadosList"
              :return-object="false"
              :item-text="(item) => item.codigo"
              :item-value="(item) => item.codigo"
              v-model="form.estadualidade"
              @change="(event) => atualizarEstado(event)"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>Município de nascimento</e-label>
            <e-autocomplete
              :items="municipiosNasc"
              :return-object="false"
              :item-text="(item) => item"
              :item-value="(item) => item"
              v-model="form.municipalidade"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>

          <v-col class="pt-0 pb-0" cols="12">
            <e-label
              >Aluno(a) com deficiência, transtorno do espectro autista ou altas
              habilidades/superdotação</e-label
            >
            <v-switch v-model="form.especial"></v-switch>
          </v-col>
          <template v-if="form.especial">
            <v-col cols="12">
              <h4>
                Tipo de deficiência, transtorno do espectro autista ou altas
                habilidades/superdotação
              </h4>
            </v-col>
            <v-col class="pt-0 pb-0" cols="4">
              <e-label>Deficiência</e-label>

              <e-autocomplete
                clearable
                :items="$constants.tipoDeDeficienciaList"
                :return-object="false"
                v-model="form.tipos_de_deficiencia"
                label="Selecione uma opção"
                multiple
                dense
                solo
              />
            </v-col>
            <v-col class="pt-0 pb-0" cols="4">
              <e-label>Transtorno do espectro autista</e-label>

              <e-autocomplete
                clearable
                :items="$constants.tipoDeTranstornoDoEspectroAutistaList"
                :return-object="false"
                v-model="form.tipos_de_transtornos"
                label="Selecione uma opção"
                multiple
                dense
                solo
              />
            </v-col>
            <v-col class="pt-0 pb-0" cols="4">
              <e-label>Altas habilidades/superdotação</e-label>

              <e-autocomplete
                clearable
                :items="$constants.tipoDeAltasHabilidadesList"
                :return-object="false"
                v-model="form.tipos_de_altas_habilidades"
                label="Selecione uma opção"
                multiple
                dense
                solo
              />
            </v-col>
          </template>

          <v-col class="pt-0 pb-0" cols="12">
            <h4>
              Recursos para uso do(a) aluno(a) em sala de aula e para participação em avaliações do
              Inep (Saeb)
            </h4>
            <v-row class="">
              <v-col
                class="pt-0 pb-0"
                cols="4"
                v-for="(el, index) of $constants.recursosSaebList"
                :key="index"
              >
                <v-checkbox v-model="recursos_saeb[index]" :label="el" :value="el"></v-checkbox>
              </v-col>
            </v-row>
          </v-col>

          <v-col class="pt-4 pb-4" cols="3">
            <e-label><strong>A família apresentou ‘CID’ do(a) estudante</strong></e-label>
            <v-switch
              v-model="form.possui_cid"
              color="primary"
              :label="form.possui_cid ? 'Sim' : 'Não'"
              hide-details
            ></v-switch>
          </v-col>
          <template v-if="form.possui_cid">
            <v-col class="pt-5 pb-0" cols="4">
              <e-label> Número CID</e-label>
              <v-text-field v-model="form.numero_do_cid" dense solo />
            </v-col>
            <v-col class="pt-5 pb-0" cols="5">
              <e-label>Anexar CID</e-label>
              <div class="dropboxStyles">
                <input
                  id="fileUpload2"
                  class="input-file-upload"
                  type="file"
                  @change="selectFile2"
                />
                <span v-if="!pdf2" style="cursor: pointer">Upload</span>

                <span v-if="pdf2">
                  <v-chip v-if="pdf2" class="ma-2" color="green" label text-color="white">
                    {{ pdf2.target.files[0].name }}
                  </v-chip>
                </span>
                <span v-if="form?.anexo_cid && !pdf2">
                  <v-chip
                    v-if="form?.anexo_cid && !pdf2"
                    class="ma-2"
                    color="green"
                    label
                    text-color="white"
                  >
                    {{ form?.anexo_cid }}
                  </v-chip>
                </span>

                <span v-if="pdf2">
                  <v-btn
                    v-if="pdf2"
                    class="mx-2"
                    fab
                    dark
                    small
                    color="red"
                    @click="apagarAnexoCid"
                  >
                    <v-icon dark> mdi-delete </v-icon>
                  </v-btn>
                </span>

                <span v-if="form.anexo_cid">
                  <v-btn
                    v-if="form.anexo_cid"
                    class="mx-2"
                    fab
                    dark
                    small
                    color="red"
                    @click="apagarAnexoCidForm"
                  >
                    <v-icon dark> mdi-delete </v-icon>
                  </v-btn>
                </span>

                <span v-if="form.anexo_cid">
                  <v-btn
                    v-if="form.anexo_cid"
                    class="mx-2"
                    fab
                    dark
                    small
                    color="blue"
                    @click="downloadAnexoCid"
                  >
                    <v-icon dark> mdi-download </v-icon>
                  </v-btn>
                </span>
              </div>
            </v-col>
          </template>

          <v-col cols="12">
            <h4>DOCUMENTO</h4>
          </v-col>
          <v-col class="pt-0 pb-0" cols="5">
            <e-label> Número de Identificação Social (NIS) </e-label>
            <v-text-field v-model="form.numero_nis" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="7">
            <e-label> Número da matrícula da certidão de nascimento (certidão nova) </e-label>
            <v-text-field v-model="form.matricula_certidao_de_nascimento" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="7">
            <e-label> Número do cartão SUS</e-label>
            <v-text-field v-model="form.numero_cartao_sus" dense solo />
          </v-col>

          <v-col class="pt-0 pb-0" cols="5">
            <e-label>Anexar cartão SUS</e-label>
            <div class="dropboxStyles">
              <input id="fileUpload" class="input-file-upload" type="file" @change="selectFile" />
              <span v-if="!pdf" style="cursor: pointer">Upload</span>

              <span v-if="pdf">
                <v-chip v-if="pdf" class="ma-2" color="green" label text-color="white">
                  {{ pdf.target.files[0].name }}
                </v-chip>
              </span>
              <span v-if="form?.cartaosus && !pdf">
                <v-chip
                  v-if="form?.cartaosus && !pdf"
                  class="ma-2"
                  color="green"
                  label
                  text-color="white"
                >
                  {{ form?.cartaosus }}
                </v-chip>
              </span>

              <span v-if="pdf">
                <v-btn v-if="pdf" class="mx-2" fab dark small color="red" @click="apagarCartaoSus">
                  <v-icon dark> mdi-delete </v-icon>
                </v-btn>
              </span>

              <span v-if="form.cartaosus">
                <v-btn
                  v-if="form.cartaosus"
                  class="mx-2"
                  fab
                  dark
                  small
                  color="red"
                  @click="apagarCartaoSusForm"
                >
                  <v-icon dark> mdi-delete </v-icon>
                </v-btn>
              </span>

              <span v-if="form.cartaosus">
                <v-btn
                  v-if="form.cartaosus"
                  class="mx-2"
                  fab
                  dark
                  small
                  color="blue"
                  @click="downloadCartaoSus"
                >
                  <v-icon dark> mdi-download </v-icon>
                </v-btn>
              </span>
            </div>
          </v-col>
          <v-col cols="12">
            <h4>DADOS SOBRE A ÁREA RESIDENCIAL DO(A) ALUNO(A)</h4>
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label> País de residência </e-label>
            <v-text-field v-model="form.pais_residencia" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="4">
            <e-label> CEP </e-label>
            <v-text-field v-mask="`#####-###`" v-model="form.cep" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="2">
            <e-label> UF </e-label>
            <e-autocomplete
              :items="$constants.estadosList"
              :return-object="false"
              :item-text="(item) => item.codigo"
              :item-value="(item) => item.codigo"
              v-model="form.uf_residencia"
              @change="(event) => atualizarEstadoRes(event)"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label> Município de residência </e-label>
            <e-autocomplete
              :items="municipiosRes"
              :return-object="false"
              :item-text="(item) => item"
              :item-value="(item) => item"
              v-model="form.municipio_residencia"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label> Zona de Residência </e-label>
            <e-autocomplete
              :items="$constants.zonaResidenciaList"
              :return-object="false"
              v-model="form.zona_residencia"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>Localização diferenciada de residência</e-label>
            <e-autocomplete
              :items="$constants.localizacaoDiferenciadaDeResidenciaList"
              :return-object="false"
              v-model="form.zona_diferenciada_residencia"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>Transporte escolar público</e-label>
            <v-switch
              v-model="form.utiliza_transporte_publico"
              color="primary"
              :label="form.utiliza_transporte_publico ? 'Utiliza' : 'Não utiliza'"
              hide-details
            ></v-switch>
          </v-col>
          <!-- <v-col cols="12">
            <v-btn
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
              block
              >Enviar Dados</v-btn
            >
          </v-col> -->
          <v-col cols="6">
            <v-btn
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
              block
              @click="submitForm('alunos')"
            >
              Salvar e sair
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              color="blue darken-4 white--text"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
              block
              @click="submitForm('matriculas/create')"
            >
              Salvar e matricular
            </v-btn>
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>
  </main-template>
</template>

<script>
// import { mapActions, mapGetters } from "vuex";
import { mapGetters } from "vuex";
import ELabel from "../../components/ELabel.vue";

const STATUS_INITIAL = 0;
const STATUS_SAVING = 1;
const STATUS_SUCCESS = 2;
const STATUS_FAILED = 3;
export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    form: {
      deep: true,
      handler() {
        // if (formValue) {
        //   this.recursos_saeb = formValue.recursos_saeb;
        // } else {
        //   this.recursos_saeb = [];
        // }
        if (this.nextInepTimeoutCall) {
          clearTimeout(this.nextInepTimeoutCall);
        }

        this.nextInepTimeoutCall = setTimeout(() => {
          this.verificarCodigoINEP();
        }, 500);
      },
    },
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
    selected() {
      return this.form.selected;
    },
    // ...mapGetters("Alunos", ["alunosList", "carregandoAlunos"]),
    // ...mapGetters("Alunos", ["alunos"]),
    ...mapGetters("Auth", ["user"]),
  },
  mounted() {
    this.reset();
    this.loadFormData();
  },

  data() {
    return {
      pdf: null,
      pdf2: null,
      submittingForm: false,
      recursos_saeb: [],
      form: {
        naturalidade: "Brasileira",
      },
      nextInepTimeoutCall: null,
      pesquisandoINEP: false,
      municipiosNasc: [],
      municipiosRes: [],
      edit: true,
      imageAluno: null,
      countImagem: false,
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: "photos",
    };
  },

  methods: {
    // ...mapActions("Alunos", ["loadAlunos"]),
    atualizarEstado(value) {
      if (value) {
        this.municipiosNasc = this.$constants.municipios[value].cidades;
      }
    },

    atualizarEstadoRes(value) {
      if (value) {
        this.municipiosRes = this.$constants.municipios[value].cidades;
      }
    },

    apagarCartaoSus() {
      document.querySelector("#fileUpload").value = "";
      this.pdf = null;
    },

    apagarCartaoSusForm() {
      document.querySelector("#fileUpload").value = "";
      this.pdf = null;
      this.form.cartaosus = null;
    },

    apagarAnexoCid() {
      document.querySelector("#fileUpload2").value = "";
      this.pdf2 = null;
    },

    apagarAnexoCidForm() {
      document.querySelector("#fileUpload2").value = "";
      this.pdf2 = null;
      this.form.anexo_cid = null;
    },

    selectFile(event) {
      if (this.pdf && event.target.files[0].type === "application/pdf") {
        this.pdf = null;
      }

      if (event.target.files[0].type === "application/pdf") {
        this.pdf = event;
        return;
      }

      this.$toast.danger("Formato de arquivo Inválido");
    },

    selectFile2(event) {
      if (this.pdf2 && event.target.files[0].type === "application/pdf") {
        this.pdf2 = null;
      }

      if (event.target.files[0].type === "application/pdf") {
        this.pdf2 = event;
        return;
      }

      this.$toast.danger("Formato de arquivo Inválido");
    },

    async pegarAluno() {
      try {
        const { aluno_id } = this.$route.params;
        const aluno = await this.$services.alunosService.pegarAluno(aluno_id);
        return aluno;
      } catch (error) {
        return this.$handleError(error);
      }
    },

    async downloadCartaoSus() {
      decodeURI();
      try {
        const { aluno_id } = this.$route.params;
        const data = await this.$services.alunosService.downloadCartaoSus(aluno_id);
        return data;
      } catch (error) {
        return this.$handleError(error);
      }
    },

    async downloadAnexoCid() {
      decodeURI();
      try {
        const { aluno_id } = this.$route.params;
        const data = await this.$services.alunosService.downloadAnexoCid(aluno_id);
        return data;
      } catch (error) {
        return this.$handleError(error);
      }
    },

    async submitForm(redirectTo) {
      this.form.especial = Boolean(this.form.especial);
      this.form.recursos_saeb = this.recursos_saeb;
      this.submittingForm = true;

      try {
        console.log(this.form);
        console.log(this.pdf);
        if (this.editing) {
          await this.$services.alunosService.atualizarAluno(this.form);

          if (this.pdf) {
            const formData = new FormData();
            formData.append("file", this.pdf.target.files[0]);
            await this.$services.alunosService.uploadCartaoSus(formData, this.form.id);
          }
          if (this.pdf2) {
            const formData = new FormData();
            formData.append("file", this.pdf2.target.files[0]);
            await this.$services.alunosService.uploadAnexoCid(formData, this.form.id);
          }

          this.$toast.success("Aluno atualizado com sucesso");
        } else {
          const alunoResponse = await this.$services.alunosService.criarAluno(this.form);

          if (this.pdf) {
            if (alunoResponse) {
              const formData = new FormData();
              formData.append("file", this.pdf.target.files[0]);
              await this.$services.alunosService.uploadCartaoSus(formData, alunoResponse.id);
            }
          }
          if (this.pdf2) {
            if (alunoResponse) {
              const formData = new FormData();
              formData.append("file", this.pdf2.target.files[0]);
              await this.$services.alunosService.uploadAnexoCid(formData, alunoResponse.id);
            }
          }

          this.$toast.success("Aluno criado com sucesso");
        }
        this.$router.push({ path: `/${redirectTo}` });
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
    },

    async loadFormData() {
      const aluno = await this.pegarAluno();
      if (aluno) {
        this.edit = false;
      }
      this.atualizarEstadoRes(aluno.uf_residencia);
      this.atualizarEstado(aluno.estadualidade);
      if (aluno.imagem) {
        this.currentStatus = STATUS_SAVING;
      }
      this.recursos_saeb = aluno.recursos_saeb ?? [];
      this.form = {
        ...aluno,
      };
    },

    async uploadImage(id) {
      try {
        await this.$services.imageService.uploadImage("alunos/upload-image", this.imageAluno, id);
      } catch (error) {
        this.$handleError(error);
      }
    },

    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
    },

    filesChange(fileList) {
      // handle file changes
      if (!fileList.length) return;

      // append the files to FormData
      if (fileList.length > 1) {
        this.countImagem = true;
        setTimeout(() => {
          this.countImagem = false;
        }, 4000);
      }
      Array.from(Array(fileList.length).keys()).map((x) => {
        this.imageAluno = fileList[x];
        return fileList[x];
      });

      this.currentStatus = STATUS_SAVING;
    },

    async verificarCodigoINEP() {
      if (this.editing || !this.form.codigo_inep) return;
      this.pesquisandoINEP = true;
      try {
        const aluno = await this.$services.alunosService.getAlunoComINEP(this.form.codigo_inep);
        if (aluno) {
          if (aluno.configuracao_id !== this.user.configuracao_id) {
            this.$modals.warn({
              title: "Você gostaria de transferir esse(a) aluno(a)?",
              message: `Encontramos o(a) aluno(a) <strong>${aluno.nomecompleto}</strong> com INEP <strong>${aluno.codigo_inep}</strong> registrado em outra escola, você gostaria de transferí-lo para sua escola?`,
              buttons: [
                {
                  text: "Cancelar",
                  callback: () => {
                    this.$modals.close();
                  },
                  props: {
                    color: "warning",
                    small: true,
                  },
                },
                {
                  text: "Confirmar",
                  callback: async () => {
                    this.$modals.setOptions({ loading: true });
                    await this.$services.alunosService.atualizarAluno({
                      ...aluno,
                      configuracao_id: this.user.configuracao_id,
                    });
                    this.$modals.close();
                    this.$toast.success("Aluno transferido com sucesso!");
                    this.$router.push({ name: "alunos" });
                  },
                  props: {
                    color: "success",
                    // text: true,
                    small: true,
                  },
                },
              ],
            });
          } else {
            this.$modals.info({
              title: "Este(a) aluno(a) já está cadastrado na sua escola!",
              message: "Verificamos que este(a) aluno(a) já está cadastrado na sua escola, ",
              buttons: [
                {
                  text: "Confirmar",
                  callback: () => {
                    this.$modals.close();
                    this.$toast.info("Este(a) aluno(a) já está cadastrado na sua escola");
                    this.$router.push({ name: "alunos" });
                  },
                  props: {
                    color: "success",
                    small: true,
                  },
                },
              ],
            });
          }
        }
      } catch (error) {
        this.$handleError(error);
      }
      this.pesquisandoINEP = false;
    },
  },
};
</script>

<!-- SASS styling -->
<style lang="scss">
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}

.input-file-upload {
  opacity: 0;
  width: 100%;
  height: 60px;
  position: absolute;
  cursor: pointer;
}

.dropboxStyles {
  outline: 2px dashed #b0bec5;
  background-color: #f5f5f5;
  padding: 9px;
  border-radius: 8px;
  position: relative;
  transition: background-color 0.3s ease;
}

.dropboxStyles:hover {
  background-color: #e0f7fa;
}

.upload-text {
  cursor: pointer;
  font-size: 16px;
  color: #607d8b;
}

.v-chip {
  background-color: #4caf50 !important;
}

.v-btn {
  margin-left: 8px;
}
</style>
