import Axios from "@/plugins/Axios";

class MatriculasService {
  async syncAll() {
    const response = await Axios().get("matriculas");
    return response.data;
  }

  async matricular(form) {
    form.matriculas = form.matriculas.map((matricula) => ({
      aluno_id: matricula.aluno_id,
      turma_id: matricula.turma_id,
      serie_id: matricula.serie_id,
    }));
    const response = await Axios().post("matriculas", form);
    const novaMatricula = response.data;

    return novaMatricula;
    // store.commit("Matriculas/adicionarMatricula", novaMatricula);
  }

  async historico(aluno_id) {
    const response = await Axios().get(`matriculas/historico/${aluno_id}`);
    return response;
  }

  async transferirMatricula(form) {
    const response = await Axios().post("matriculas/transferir-matricula", form);
    return response.data;
  }

  async atualizarMatricula(matricula) {
    const response = await Axios().put(`matriculas/${matricula.id}`, matricula);
    const matriculaAtualizada = response.data;
    // store.commit("Matriculas/atualizarMatricula", matriculaAtualizada);
    return matriculaAtualizada;
  }

  async deletarMatricula(matricula) {
    const response = await Axios().delete(`matriculas/${matricula.id}`);
    const matriculaDeletada = response.data;
    // store.commit("Matriculas/deletarMatricula", matricula.id);
    return matriculaDeletada;
  }

  async recuperarMatricula(matricula) {
    return this.atualizarMatricula({ ...matricula, deleted_at: null });
  }

  async ocultar(matricula) {
    const response = await Axios().get(`matriculas/ocultar/${matricula.id}`);
    const matriculaOcultado = response.data;
    // store.commit("Matriculas/atualizarMatricula", matriculaOcultado);
    return matriculaOcultado;
  }

  async verificarSeAlunoPossuiMatriculaNoMunicipio(aluno_id) {
    const response = await Axios().post(
      "matriculas/verificar-se-aluno-possui-matricula-no-municipio",
      { aluno_id }
    );
    return response.data;
  }

  async getMatricula(matricula_id) {
    const response = await Axios().get(`matriculas/get-matricula/${matricula_id}`);
    return response.data;
    // store.commit("Matriculas/atualizarMatricula", matriculaOcultado);
  }

  async getMediasdaMatricula(matricula_id) {
    const response = await Axios().get(
      `matriculas/desempenho-de-notas-do-aluno/matricula/${matricula_id}`
    );
    return response.data;
  }

  async getFaltasdaMatricula(matricula_id) {
    const response = await Axios().get(
      `matriculas/desempenho-de-faltas-do-aluno/matricula/${matricula_id}`
    );
    return response.data;
  }

  async qtd() {
    const response = await Axios().get("matriculas/qtd");
    return response.data;
  }

  async qtdPorFranquia(franquiaId) {
    const response = await Axios().get(`matriculas/qtdPorFranquia/${franquiaId}`);
    return response.data;
  }

  async taxaEvasao(franquiaId) {
    const response = await Axios().get(`matriculas/taxaEvasao/${franquiaId}`);
    return response.data;
  }

  async bloqueioDeMatriculas(data) {
    const response = await Axios().post(`matriculas/bloqueioDeMatriculas`, data);
    return response.data;
  }

  async verificarEstadoBloqueio() {
    try {
      const response = await Axios().get("matriculas/verificarEstadoBloqueio");
      // console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("Erro ao verificar estado de bloqueio:", error);
      throw error;
    }
  }
}

export default new MatriculasService();
